<template>
  <div class="shop">
    <div class="shop-conf-container" v-show="isShow">
      <div class="shop-conf-title">商城管理</div>
      <ul class="shop-conf-item">
        <li class="shop-conf-item-detail" v-for="tab in tabs"
            :class="{active:  $route.name.indexOf(tab.value) !== -1}"
            @click="setActive(tab.value)"> {{tab.name}}
        </li>
      </ul>
    </div>
    <div class="shop-detail-container" :class="isShow? 'padding': ''">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

  export default {
    name: '',
    components: {
    },
    mounted: function () {
    },
    watch: {
      // $route(to, from) {
      //   let isShow = false
      //   this.tabs.forEach(item => {
      //     if (to.name === item.value) {
      //       isShow = true
      //     }
      //   })
      //   this.isShow = isShow
      // }
    },
    data: function () {
      return {
        isShow: true,
        tabs: [
          {name: '商品管理', value: 'marketing.shop.goods'},
          {name: '商城设置', value: 'marketing.shop.set'},
        ]
      }
    },
    methods: {
      setActive(val) {
        this.$router.push({name: val})
      }
    },
  }
</script>

<style lang="scss">
  .shop {
    @include set_bg_color($background-color-light, $background-color-dark);
    border-radius: $border-radius;
    padding: $padding;
    display: flex;

    min-height: 618px;

    .shop-conf-container {
      width: 144px;
      padding-right: $padding;
      border-right: 1px solid;
      @include set_border_color($--border-color, $--border-color-dark);

      .shop-conf-title {
        width: 100%;
        text-align: center;
        @include set_font_color($_theme_color, $--font-color-dark);
        @include set_bg_color(#eef8ff, #454954);
        padding: 4px 0;
        border-radius: $border-radius;
        margin-bottom: $padding;
      }

      .shop-conf-item {
        padding-left: $padding-8;

        .active {
          color: $_theme_color;
        }

        &-detail {
          cursor: pointer;
          margin-bottom: $padding-8;
        }

        &-detail:hover {
          color: $_theme_color;
        }
      }
    }

    .shop-detail-container {
      flex: 1;
    }
    .padding{
      padding-left: $padding;
    }
  }
</style>


